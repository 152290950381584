import React from "react"
import {
  ChildContainer,
  Description,
  ParentContainer,
  Title,
  Image,
  TitleSub,
  SubText,
  Ul,
  LearnMore,
  SvgHolder,
  TitleHolder,
} from "../../../ToolsStyles/UsefulToolsStyled"
import ImageIcon from "../icons/image.svg"

const UsefulTools = () => {
  return (
    <ParentContainer>
      <TitleHolder>
        <Title>Continue to organize this PDF</Title>
        <Description>
          Here are a couple other tools that you may want to use on your current
          PDF document.
        </Description>
        <LearnMore to="/pdf-online-tools/">
          View all Online tools &rarr;
        </LearnMore>
      </TitleHolder>

      <ChildContainer>
        <SvgHolder>
          <ImageIcon />
        </SvgHolder>
        <TitleSub>PDF to PNG</TitleSub>
        <SubText>Convert a PDF to PNG.</SubText>
        <LearnMore to="/pdf-to-png/">Continue &rarr;</LearnMore>
      </ChildContainer>

      <ChildContainer>
        <SvgHolder>
          <ImageIcon />
        </SvgHolder>
        <TitleSub>JPG to PDF</TitleSub>
        <SubText>Convert a JPG image to PDF.</SubText>
        <LearnMore to="/jpg-to-pdf/">Continue &rarr;</LearnMore>
      </ChildContainer>
    </ParentContainer>
  )
}

export default UsefulTools
