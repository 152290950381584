import React, { useState } from "react"
import {
  Bacground,
  Expand,
  LeftChild,
  ParentContainer,
  PlusSymbol,
  RightChild,
  SubText,
  Support,
  Title,
  Ul,
} from "../../../ToolsStyles/FrequentlyAskedQuestionsStyled"
import plus from "../../../merge/MergeAssets/plus.png"
import Question from "./Question"

const FrequentlyAskedQuestions = () => {
  const [show, setShow] = useState(1)
  return (
    <ParentContainer>
      <LeftChild>
        <Support>Support</Support>
        <Title>Frequently Asked Questions</Title>
        <SubText>
          Could not find what you were looking for? <br /> Write us at&nbsp;
          <a href="mailto: support@pdfpro.com">support@pdfpro.com</a>
        </SubText>
      </LeftChild>

      <RightChild>
        <Ul>
          <Question
            setActive={setShow}
            active={show}
            question={"What is the PDF to JPG Converter used for?"}
            id={1}
            answer={
              "Use PDF Pro's PDF to JPG Converter anytime you want to convert PDF to JPG for free, online."
            }
          />

          <Question
            setActive={setShow}
            active={show}
            question={"What functions does the PDF to JPG Converter have?"}
            id={2}
            answer={
              "The PDF to JPG Converter allows you to instantly convert PDFs to JPG directly in your browser."
            }
          />

          <Question
            setActive={setShow}
            active={show}
            question={"Can you convert PDF to JPG for free?"}
            id={3}
            answer={
              "Yes! PDF Pro’s PDF to JPG Converter is 100% free to use. There are no sign-ups needed to use this tool, and there are no limits on the number of times you can use this tool."
            }
          />
          <Question
            setActive={setShow}
            active={show}
            question={"How do I convert PDF to JPG for free?"}
            id={4}
            answer={
              "Simply visit PDF Pro's PDF to JPG Converter, upload your PDF, press Convert, then download your new JPG image."
            }
          />
        </Ul>
      </RightChild>
    </ParentContainer>
  )
}

export default FrequentlyAskedQuestions
